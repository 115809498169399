import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function channelQueueList() {
  const headers = [
    { text: '#', value: 'id', sortable: false },
    { text: '频道名称', value: 'channelName', sortable: false },
    { text: '频道链接', value: 'channelLink', sortable: false },
    { text: '签约平台', value: 'platform', sortable: false },
    { text: '发起人', value: 'createdUser', sortable: false },
    { text: '发起时间', value: 'createdAt', sortable: false },
    { text: '审核状态', value: 'statusList', sortable: false },
    { text: '审核时间', value: 'reviewAt', sortable: false },
    { text: '操作', value: 'action', sortable: false },
  ]
  const queueList = ref([])
  const totalLists = ref(0)
  const page = ref(1)
  const pageSize = ref(10)
  const pageLength = ref(15)
  const createdUser = ref('')
  const channelName = ref('')
  const platformFilter = ref(null)
  const statusFilter = ref(null)
  const loading = ref(false)
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  })

  const fetchQueueList = () => {
    loading.value = true
    store.dispatch('channel-list/fetchMyChannel', {
      pageSize: pageSize.value,
      page: page.value,
    })
      .then(response => {
        const { data, total } = response.data
        totalLists.value = total
        pageLength.value = Math.ceil(totalLists.value / pageSize.value)
        console.log(pageLength.value)
        queueList.value = data
        loading.value = false
      })
      .catch(error => {
        loading.value = false
        console.log(error)
      })
  }
  fetchQueueList()

  const resolveContractType = type => {
    if (type === '1') return '新签'
    if (type === '2') return '续签'
    if (type === '3') return '重签'

    return '-'
  }

  const resolveBankType = type => {
    if (type === '1') return '企业'
    if (type === '2') return '个人'
    if (type === '3') return '外国'

    return '-'
  }

  watch([page, pageSize], () => {
    fetchQueueList()
  })

  return {
    fetchQueueList,
    totalLists,
    queueList,
    page,
    pageSize,
    pageLength,
    options,
    headers,
    loading,
    createdUser,
    channelName,
    platformFilter,
    statusFilter,
    resolveBankType,
    resolveContractType,
  }
}
