<template>
  <div>
    <div
      v-if="loading"
      class="d-flex justify-center"
    >
      <div class="my-10">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        加载中，请稍等...
      </div>
    </div>
    <div
      v-else
    >
      <div
        v-if="queueList.length === 0"
        class="d-flex justify-center align-center"
      >
        暂无数据!
      </div>
      <v-card
        v-if="queueList.length !== 0"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <v-card
          v-for="item in queueList"
          :key="item.id"
        >
          <v-card-title :style="`${$vuetify.theme.isDark === true?'background-color: #3B355A':'background-color: #E8E9EE'}`">
            {{ item.title }}({{ item.pageId }})
            <v-spacer></v-spacer>
            <router-link
              :class="`${ item.platform==='facebook'?'info':'error' }--text`"
              to
            >
              {{ item.platform==='facebook'?'facebook':'youtube' }}
            </router-link>
          </v-card-title>
          <v-card-text>
            <v-row
              style="margin-left: 20px;margin-top: 25px;"
              cols="12"
            >
              <v-col
                lg="6"
                cols="12"
                class="d-flex"
              >
                <div>
                  <v-img
                    class="thumbnail"
                    :src="item.platform==='facebook'?item.pictureUrl:item.thumbnail"
                  ></v-img>
                </div>
                <div class="ml-4 mr-14 d-flex flex-column">
                  <span>名称：{{ item.title }}</span>
                  <span class="text-break mt-2">简介：{{ item.platform==='facebook'?item.info:item.description }}</span>
                </div>
              </v-col>
              <v-col md="3">
                <div class="d-flex flex-column">
                  <span>领域：{{ item.platform==='facebook'?item.category:item.name }}</span>
                  <span class="mt-5">签约状态：<span :class="`${item.flag==='1'?'success':'error'}--text`">{{ item.flag==='1'?'已签约':'未签约' }}</span></span>
                </div>
              </v-col>
              <v-col md="2">
                <div class="d-flex flex-column">
                  <span>地区：{{ item.platform==='facebook'?item.location:item.country }}</span>
                  <v-btn
                    v-show="item.flag==='1'"
                    class="mt-7"
                    color="primary"
                    :to="{ name:'project-channel-details', query:item.id }"
                    style="width: 100px"
                  >
                    查看合约
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <template>
          <div class="text-center">
            <v-container>
              <v-row justify="center">
                <v-col cols="8">
                  <v-container class="max-width">
                    <v-pagination
                      v-model="page"
                      class="my-4"
                      :length="pageLength"
                    ></v-pagination>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </template>
      </v-card>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import storeModule from './queueStoreModule'
import channelQueueList from './channelQueueList'

export default {
  components: {
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'channel-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const isAddNewSidebarActive = ref(false)
    const itemId = ref(0)
    const itemValue = ref({ })
    const isAdd = ref(false)

    const {
      queueList,
      fetchQueueList,
      headers,
      options,
      totalLists,
      createdUser,
      channelName,
      platformFilter,
      statusFilter,
      loading,
      page,
      pageSize,
      pageLength,
    } = channelQueueList()

    const addCP = () => {
      isAdd.value = true
      isAddNewSidebarActive.value = !isAddNewSidebarActive.value
    }

    const search = () => {
      options.value.page = 1
      fetchQueueList()
    }

    const resolveAction = (...args) => {
      if (args.some(status => status === 2)) return { text: '重新提交', color: 'warning' }
      if (args.every(status => status === undefined)) return { text: '撤回审核', color: 'primary' }

      return false
    }

    // 导入通过相关字符串获取数据的函数
    const open = ref([1, 2])
    const isRemoved = ref(false)

    return {
      queueList,
      totalLists,
      page,
      pageSize,
      pageLength,
      fetchQueueList,
      headers,
      loading,
      open,
      isRemoved,
      itemId,
      itemValue,
      isAdd,
      isAddNewSidebarActive,
      options,
      createdUser,
      channelName,
      platformFilter,
      statusFilter,
      resolveAction,
      search,
      addCP,
    }
  },
}
</script>

<style scoped>
.v-card__text{
  padding: 0;
}
.thumbnail{
  width: 147px;
  height: 147px;
  border-radius: 6px;
}
</style>
